<template>
  <div class="page-box">
    <BaseSubTitle title="我的银行卡" />
    <a-spin :spinning="bankCardListLoading" v-if='authType !== null'>
      <a-row type="flex" align="middle">
        <a-col :xs="12" :sm="12" :md="12" :lg="8" :xl="8" :xxl="6" v-for="item in bankCardList" :key="item.shopBankCardId">
          <div class="bank-card-item">
            <div class="bank-card-item-body">
              <div class="card-info">
                <div class="card-name-box">
                  <icon-svg class="bank-img" v-if="item.bankCode" :icon-class="'bank_'+item.bankCode" />
                  <div class="bank-img" style='color:red;line-height:40px;text-align:center;font-weight:900;font-size:24px;' v-else>公</div>
                  <div class="card-name">{{item.bankCode?bankList.find(value=>Number(value.dictItemCode) === Number(item.bankCode)).dictItemName:item.bankBranchName}}</div>
                  <div v-if="item.defaultFlag" style="font-size:14px;color:#1890ff;border: 1px solid #1890ff;padding: 0 4px;">默认</div>
                </div>
                <div class="card-no">**** **** **** **** {{item.bankCardNo.substr(-4)}}</div>
              </div>
              <img src="@/assets/bank_bg_cross.png" class='bank-card-bg' alt="">
            </div>
            <div class="bank-card-item-foot">
              <div class='opt-box'>
                <a-button type="link" v-pms="'edit'" @click="editBank(item)">修改</a-button>
                <a-button type="link" v-pms="'delete'" @click='deleteBank(item.shopBankCardId)'>解绑</a-button>
              </div>
            </div>
          </div>
        </a-col>
        <a-col v-if='authType === "2"' :xs="12" :sm="12" :md="12" :lg="8" :xl="8" :xxl="6" @click="addBank(0)" v-pms="'add'">
          <div class="add-bank bank-card-item">
            <a-icon type="plus" class="add-icon"></a-icon>
            <p>绑定银行卡</p>
          </div>
        </a-col>
        <a-col v-if='authType === "1"' :xs="12" :sm="12" :md="12" :lg="8" :xl="8" :xxl="6" @click="addBank(2)" v-pms="'add'">
          <div class="add-bank bank-card-item">
            <a-icon type="plus" class="add-icon"></a-icon>
            <p>添加对公账户</p>
          </div>
        </a-col>
        <a-col v-if='authType === "3"' :xs="12" :sm="12" :md="12" :lg="8" :xl="8" :xxl="6" @click="addBank(1)" v-pms="'add'">
          <div class="add-bank bank-card-item">
            <a-icon type="plus" class="add-icon"></a-icon>
            <p>添加法人银行卡</p>
          </div>
        </a-col>
      </a-row>
    </a-spin>
    <div v-else class='no-auth-type'>
      <a-icon type="exclamation-circle" class='warn-icon' />
      <div>
        请提交 <span style='color:red;cursor:pointer;' @click='$router.push("/shop/authentication")'>店铺认证</span> 后再进行此操作
      </div>
    </div>
    <a-modal v-model="visible" :title="optionType === 0?'绑定银行卡':optionType === 1?'修改绑定银行卡信息':''" :confirm-loading="bankModalConfirmLoading" @ok="submit">
      <a-form-model ref="formRef" :model="formData" @submit.native.prevent :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules">
        <a-form-model-item :label="nameLabelText + ':'" prop="holderName">
          <a-input v-model="formData.holderName" allowClear placeholder="请填写" />
        </a-form-model-item>
        <a-form-model-item v-if='addBankType !== 2' label="证件号码：" prop="idcardCode">
          <a-input v-model="formData.idcardCode" allowClear placeholder="请填写" />
        </a-form-model-item>
        <a-form-model-item v-if='addBankType !== 2' label="开户银行：" prop="bankCode">
          <a-select v-model="formData.bankCode" placeholder="请选择" style="min-width: 120px">
            <a-select-option v-for="item in bankList" :key="item.dictItemId" :value="item.dictItemCode">{{item.dictItemName}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if='addBankType === 2' label="开户行：" prop="bankBranchName">
          <a-input v-model="formData.bankBranchName" allowClear placeholder="请填写" />
        </a-form-model-item>
        <a-form-model-item :label="addBankType === 2?'收款账号：':'银行卡号：'" prop="bankCardNo">
          <a-input v-model="formData.bankCardNo" allowClear placeholder="请填写" />
        </a-form-model-item>
        <a-form-model-item label="是否默认：" prop="defaultFlag">
          <a-radio-group v-model="formData.defaultFlag" :options="options" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal v-model="authVisible" title="短信验证" okText="验证" :confirm-loading="authModalConfirmLoading" @ok="authFn">
      <a-spin :spinning="authModalLoading">
        <p v-if="shopMainData">
          为保障你的账户安全，需要进行短信验证。
          <br />验证码将发送至
          <span style="color:red">店铺主</span>的手机号：
          <span style="font-weight:500;color:#000">{{shopMainData.phone}}</span>。
        </p>
        <div class="auth-flex-box" v-if="shopMainData">
          <sms-code ref='smsCode' :type="2" :phone='shopMainData.orginPhone' @isGeted='(res)=>{isGetSms = res}' v-model='authSmsCode'></sms-code>
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, ref } from '@vue/composition-api'
import { capital, dict } from '@/api'
import { checkIdCard } from '@/utils/validator'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
export default {
  name: 'BankCard',
  setup (props, { root }) {
    const rules = {
      holderName: [
        { required: true, message: '请填写持卡人', trigger: 'change' },
        {
          max: 32,
          message: '您可以尝试一下简短的持卡人',
          trigger: 'change',
        },
      ],
      idcardCode: [
        { required: true, message: '请填写证件号码', trigger: 'change' },
        { validator: checkIdCard, trigger: 'change' },
      ],
      bankBranchName: [{ required: true, message: '请填写', trigger: 'change' }],
      bankCode: [{ required: true, message: '请选择银行', trigger: 'change' }],
      bankCardNo: [
        { required: true, message: '请填写银行卡号', trigger: 'change' }
      ],
    }
    const options = [
      { label: '是', value: true },
      { label: '否', value: false },
    ]
    const labelCol = { span: 4 }
    const wrapperCol = { span: 20 }
    const state = reactive({
      authSmsCode: '',
      visible: false,
      authVisible: false,
      authModalConfirmLoading: false,
      authModalLoading: false,
      bankModalConfirmLoading: false,
      bankCardListLoading: false,
      optionType: 0,
      deleteBankId: '',
      formRef: null,
      formData: {
        idcardType: 1,
        defaultFlag: true,
      },
      shopMainData: null,
      bankList: [],
      bankCardList: [],
      authType: null,
      nameLabel: ['持卡人', '法人姓名', '公司名称'],
      nameLabelText: '',
      addBankType: 0,
    })
    const smsCode = ref(null)
    onMounted(async () => {
      await root.$store.dispatch('head/getShopInfoRequest')
      if (root.$store.getters['head/getShopInfo'].authState === 1) {
        state.authType = root.$store.getters['head/getShopInfo'].authType
      } else {
        state.authType = null
      }
      await getBankList()
      getMyBankCardList()
    })
    async function getBankList () {
      let { code, data } = await dict.getDict({
        dictTypeCode: 'BankCode',
      })
      if (code === '00000') {
        state.bankList = data.map((item) => {
          item.dictItemCode = Number(item.dictItemCode)
          return item
        })
      }
    }
    function addBank (type) {
      this.addBankType = type
      state.nameLabelText = state.nameLabel[type]
      state.formData = {
        idcardType: 1,
        type,
        defaultFlag: true,
      }
      state.authVisible = true
      state.optionType = 0
      getShopMainData()
    }
    function editBank (item) {
      this.addBankType = item.type
      state.nameLabelText = state.nameLabel[item.type]
      state.formData = item
      state.authVisible = true
      state.optionType = 1
      getShopMainData()
    }
    function deleteBank (id) {
      state.deleteBankId = id
      state.authVisible = true
      state.optionType = 2
      getShopMainData()
    }
    async function getShopMainData () {
      if (!state.shopMainData) {
        state.authModalLoading = true
        let { code, data, msg } = await capital.getShopMaster({
          shopId: getSession(SHOPID),
        })
        state.authModalLoading = false
        if (code === '00000') {
          state.shopMainData = {
            phone: data.phone || data.loginName,
            orginPhone: data.phone || data.loginName,
            md5Str: data.md5Str,
          }
          state.shopMainData.phone =
            state.shopMainData.phone.substr(0, 3) + '****' + state.shopMainData.phone.substr(-4)
          await root.$nextTick()
          smsCode.value.clearIntervalFn()
        } else {
          root.$message.error(msg || '网络错误，请重试')
        }
      } else {
        await root.$nextTick()
        smsCode.value.clearIntervalFn()
      }
    }
    async function authFn () {
      state.authModalConfirmLoading = true
      let { code, msg } = await capital.authentication({
        phone: state.shopMainData.orginPhone,
        md5Str: state.shopMainData.md5Str,
        smsCode: state.authSmsCode,
      })
      state.authSmsCode = ''
      state.authModalConfirmLoading = false
      if (code === '00000') {
        state.authVisible = false
        state.authSmsCode = ''
        if (state.optionType !== 2) {
          state.visible = true
        } else {
          root.$confirm({
            title: '提示',
            content: '确定要解绑该银行卡？',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
              let { code, msg } = await capital.deleteBank({
                shopBankCardId: state.deleteBankId,
              })
              if (code === '00000') {
                root.$message.success('解绑成功')
                getMyBankCardList()
              } else {
                root.$message.error(msg || '解绑失败')
              }
            },
          })
        }
      } else {
        root.$message.error(msg || '身份校验失败')
      }
    }
    function submit () {
      state.formRef.validate(async (valid) => {
        if (valid) {
          state.bankModalConfirmLoading = true
          let { code, msg } = await capital.saveBankCard({
            ...state.formData,
            shopId: getSession(SHOPID),
          })
          state.bankModalConfirmLoading = false
          if (code === '00000') {
            root.$message.success(
              state.optionType === 0 ? '绑定成功' : state.optionType === 1 ? '修改成功' : ''
            )
            state.visible = false
            getMyBankCardList()
          } else {
            root.$message.error(msg || '保存失败')
          }
        }
      })
    }
    async function getMyBankCardList () {
      state.bankCardListLoading = true
      let { code, msg, data } = await capital.getBankCardPage({
        shopId: getSession(SHOPID),
      })
      state.bankCardListLoading = false
      if (code === '00000') {
        state.bankCardList = data
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    return {
      smsCode,
      rules,
      options,
      labelCol,
      wrapperCol,
      ...toRefs(state),
      addBank,
      getShopMainData,
      editBank,
      deleteBank,
      authFn,
      getBankList,
      getMyBankCardList,
      submit,
    }
  },
}
</script>
<style lang="less" scoped>
.page-box {
  min-width: 980px;
}
::v-deep .bank-card-item {
  height: 168px;
  width: 292px;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
  margin: 0 auto 32px;
  background: #fff;
  color: #fff;
  .bank-card-item-body {
    font-size: 16px;
    height: 130px;
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    background-image: linear-gradient(135deg, #9db0d3, #c7d2ea);
    position: relative;
    .bank-card-bg {
      height: 64px;
      object-fit: contain;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .bank-card-item-foot {
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .public-tag {
      color: #333;
      padding-left: 16px;
    }
    .opt-box {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .ant-btn {
      padding-left: 0;
    }
  }
}

::v-deep .card-info {
  position: absolute;
  top: 16px;
  left: 16px;
  right: 16px;
  bottom: 16px;
  z-index: 2;
  .card-name-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .card-name {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .card-no {
    font-size: 18px;
    font-weight: 500;
    word-break: break-all;
    margin-top: 18px;
    text-align: center;
  }
}
::v-deep .bank-img {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  margin-right: 16px;
  border-radius: 20px;
  background: #fff;
}
::v-deep .add-icon {
  font-size: 20px;
  background: #fff;
  border-radius: 50%;
  padding: 8px;
  margin-bottom: 12px;
}
::v-deep .add-bank {
  color: #155bd4;
  border: 1px dashed #155bd4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
  box-sizing: border-box;
  cursor: pointer;
}
.auth-flex-box {
  display: flex;
  align-items: center;
  margin-top: 14px;
}
::v-deep .sms-btn {
  margin: 0;
}
.no-auth-type {
  height: calc(100vh - 260px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  font-size: 20px;
  box-sizing: border-box;
  padding-bottom: 100px;
  .warn-icon {
    font-size: 100px;
    color: #ff0000;
    margin-bottom: 32px;
  }
}
</style>
